
// import DeletePrescriptionDialog from '@/modules/prescriptions/components/delete-prescription-dialog.vue';
// import DeleteEdit from '@/modules/services/components/delete-edit.vue';
import DeleteEdit from "@/modules/services/components/delete-edit.vue";
// import {i18n} from '@/i18n';
// import firebase from 'firebase/app';
// import 'firebase/firestore';
import i18n from "@/vueI18n";
import { mapGetters, mapActions } from "vuex";

import moment from "moment";

export default {
  // props:['prescritptionsOwner'],
  name: "app-prescriptions-list-table",
  data() {
    return {
      // currentUser:true,
      topOfPage: true,
      searchInputAnimation: false,
      search: "",
      deletePrescription: false,
      id: "",
      pagination: {
        page: 1,
        rowsPerPage: this.getItemsPerPage(),
      },
      deletedId: "",
      deleteDialogHeader: false,

      // vuexFire
      rowsOld: [],
      page: 1,
      loadingTable: false,
    };
  },
  computed: {
    isRTL() {
      return i18n.locale == "ar";
    },
    rowsPerPageOptions() {
      return [2];
    },
    ...mapGetters({
      is_screen_xs: "layout/is_screen_xs",
      is_screen_sm: "layout/is_screen_sm",
      is_screen_md: "layout/is_screen_md",
      currentRoute: "prescriptions/list/currentRoute",
      currentUser: "auth/currentUser",
      mountedTable: "prescriptions/list/mountedTable",
      recordAfterLast: "prescriptions/list/recordAfterLast",
      recordBeforeFirst: "prescriptions/list/recordBeforeFirst",
      rows: "prescriptions/list/rows",
      rowsFromRoute: "prescriptions/list/rowsFromRoute",
      addedCreatorId: "prescriptions/list/addedCreatorId",
      addedRowId: "prescriptions/list/addedRowId",
      loading: "prescriptions/list/loading",
      loadingAddMorePrescriptions:
        "prescriptions/list/loadingAddMorePrescriptions",
    }),
    isScreenlg() {
      return this.$q.screen.lt.lg && this.$q.screen.lt.xl;
    },
    rowsToBeDisplayed() {
      if (this.rows.length > 0 || !this.loading) return this.rows;
      return this.rowsOld;
    },
  },
  watch: {
    rows(newval) {
      // console.log('rows==>', newval)
      if (newval.length > 0) {
        // this.rowsOld = newval
        this.setLoadFirstRecord();
        this.setLoadMoreLast();
        this.setDisablePrevious({
          currentRoute: this.currentRoute,
          currentUser: this.currentUser,
        });
        this.setDisableNext({
          currentRoute: this.currentRoute,
          currentUser: this.currentUser,
        });
      }
    },
    rowsFromRoute(change) {
      // console.log('==>rowsRoute', change)
      const page = this.$route.query.page;
      const limit = this.getItemsPerPage();
      const rowsFromRouteToBeDisplayed = change.slice(
        page * limit - limit,
        page * limit + 1
      );
      this.setRows(rowsFromRouteToBeDisplayed);
    },
    addedCreatorId(newval) {
      if (newval) {
        // this.setCreatorToRow();
      }
    },
  },
  components: {
    // [DeletePrescriptionDialog.name]: DeletePrescriptionDialog,
    [DeleteEdit.name]: DeleteEdit,
  },
  methods: {
    addNewItem() {
      this.$emit("addNewItem");
    },
    ...mapActions({
      doFetch: "prescriptions/list/doFetch",
      doFetchFromRoute: "prescriptions/list/doFetchFromRoute",
      doFetchUserPrescription: "prescriptions/list/doFetchUserPrescription",
      getPreviousRecordsBatch: "prescriptions/list/getPreviousRecordsBatch",
      setHeaderBg: "layout/setHeaderBg",
      setMountedTable: "prescriptions/list/setMountedTable",
      setLoadFirstRecord: "prescriptions/list/setLoadFirstRecord",
      setLoadMoreLast: "prescriptions/list/setLoadMoreLast",
      setDisablePrevious: "prescriptions/list/setDisablePrevious",
      setDisableNext: "prescriptions/list/setDisableNext",
      setCreatorToRow: "prescriptions/list/setCreatorToRow",
      setRows: "prescriptions/list/setRows",
      resetCursor: "prescriptions/list/resetCursor",
    }),
    datePresenter(value) {
      const language = localStorage.getItem("language");
      return moment(new Date(value.seconds * 1000))
        .locale(language)
        .format("DD-MM-yyyy hh:mm a");
      // return new Date(value.seconds * 1000).toString().slice(0, 16);
      // record.createdAt.seconds.toString().slice(0,16)
    },
    descriptionPresenter(value) {
      // const language = localStorage.getItem('language')
      // return moment(new Date(value.seconds * 1000)).locale(language).format('DD-MM-yyyy hh:mm a')
      // return new Date(value.seconds * 1000).toString().slice(0, 16);
      // record.createdAt.seconds.toString().slice(0,16)
      // debugger
      if (!this.is_screen_md) {
        return value.toString().slice(0, 40) + "...";
      } else {
        return value.toString().slice(0, 30) + "...";
      }
    },
    //#region [vuexfire]
    async loadMore() {
      // if(this.currentRoute == 'my-prescriptions'){
      //   await this.doFetchUserPrescription({currentUser: this.currentUser, limit: this.getItemsPerPage()})
      // }
      // else{
      await this.doFetch({
        limit: this.getItemsPerPage(),
        currentRoute: this.currentRoute,
        currentUser: this.currentUser,
      });
      // }
      console.log("page", this.page);
      this.page = this.page + 1;
      console.log("page", this.page);
      this.$router.push({
        query: {
          page: this.page,
        },
      });
      this.rowsOld = this.rows;
    },
    async goToPreviousPage() {
      await this.getPreviousRecordsBatch({
        limit: this.getItemsPerPage(),
        currentRoute: this.currentRoute,
        currentUser: this.currentUser,
      });
      this.page = this.page - 1;
      this.$router.push({
        query: {
          page: this.page,
        },
      });
      this.rowsOld = this.rows;
    },
    async navigateBackwardForward() {
      if (
        this.$route.name == "prescriptions" ||
        this.$route.name == "my-prescriptions"
      ) {
        if (
          this.$route.query.page &&
          parseInt(this.$route.query.page) > 0 &&
          this.isNumeric(this.$route.query.page)
        ) {
          this.page = parseInt(this.$route.query.page);
          await this.doFetchFromRoute({
            page: parseInt(this.$route.query.page),
            limit: this.getItemsPerPage(),
            currentRoute: this.currentRoute,
            currentUser: this.currentUser,
          });
        } else {
          this.page = 1;
          await this.doFetch({
            page: 1,
            limit: this.getItemsPerPage(),
            currentRoute: this.currentRoute,
            currentUser: this.currentUser,
          });
        }
      }
    },
    //#endregion
    getItemsPerPage() {
      const { screen } = this.$q;
      if (screen.lt.sm) {
        return 4;
      }
      if (screen.lt.md) {
        return 6;
      }
      if (screen.lt.lg) {
        return 9;
      }
      if (screen.lt.xl) {
        return 9;
      }
      return 9;
    },
    doGoToPrescriptionDetails(id) {
      this.$router.push(`prescriptions/${id}`);
    },
    close() {
      this.deletePrescription = false;
    },
    openDeleteDialog(id) {
      this.id = id;
      this.deletePrescription = true;
      this.deleteDialogHeader = true;
    },
    async closeDeleteDialog() {
      this.deleteDialogHeader = false;
    },
    async doRefreshRows() {
      this.closeDeleteDialog();
      this.setRows([]);
      this.resetCursor();
      this.page = 1;
      this.$router.push({
        query: {
          page: this.page,
        },
      });
      await this.doFetch({
        page: 1,
        limit: this.getItemsPerPage(),
        currentUser: this.currentUser,
        currentRoute: this.currentRoute,
      });
      // debugger
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
    isNumeric(value) {
      return /^\d+$/.test(value);
    },

    scrollHandler(scroll) {
      // debugger
      // console.log('scroll position', scroll.position);
      // if()
      if (this.currentRoute == "prescriptions") {
        if (scroll.position == 0 && !this.deleteDialogHeader) {
          this.setHeaderBg("bg-transparent");
        }
        if (scroll.position > 0 && !this.deleteDialogHeader) {
          // ================== without the setTimeout it does not work sometimes ==============
          setTimeout(() => {
            this.setHeaderBg("bg-shadow");
          }, 0);
        }
        // if(scroll.position > 0){
        //   // debugger
        //   console.log('helolllllll not zero');
        //   // this.topOfPage = false
        //   this.setHeaderBg('bg-shadow')
        //   // debugger
        //   // this.$emit('changePrescriptionHeader',false)
        // }
        if (scroll.position == 0 && this.deleteDialogHeader) {
          this.setHeaderBg("bg-shadow");
        }
      }
    },
    deletePrescriptionFromArray(value) {
      this.deletedId = value;
      // this.jobsWithCreators = this.jobsWithCreators.filter(job => job.id != this.deletedId);
      this.prescriptions = this.prescriptions.filter(
        (prescription) => prescription.id != this.deletedId
      );
    },
  },
  mounted() {
    var that = this;
    window.onpopstate = async function () {
      that.navigateBackwardForward();
    };
  },
  async created() {
    this.loadingTable = true;
    this.page =
      this.$route.query.page && parseInt(this.$route.query.page)
        ? parseInt(this.$route.query.page)
        : 1;
    this.deleteDialogHeader = false;

    this.setRows([]);
    this.resetCursor();
    if (
      this.$route.query.page &&
      parseInt(this.$route.query.page) > 0 &&
      this.isNumeric(this.$route.query.page)
    ) {
      await this.doFetchFromRoute({
        page: parseInt(this.$route.query.page),
        limit: this.getItemsPerPage(),
        currentRoute: this.currentRoute,
        currentUser: this.currentUser,
      });
    } else {
      this.page = 1;
      this.$router.push({
        query: {
          page: 1,
        },
      });
      await this.doFetch({
        page: 1,
        limit: this.getItemsPerPage(),
        currentRoute: this.currentRoute,
        currentUser: this.currentUser,
      });
    }
    this.rowsOld = this.rows;
    this.setMountedTable(true);
    this.loadingTable = false;
  },
};

import { render, staticRenderFns } from "./prescriptions-list-table.vue?vue&type=template&id=0346ff5a&"
import script from "./prescriptions-list-table.vue?vue&type=script&lang=js&"
export * from "./prescriptions-list-table.vue?vue&type=script&lang=js&"
import style0 from "./prescriptions-list-table.vue?vue&type=style&index=0&id=0346ff5a&prod&lang=scss&"
import style1 from "./prescriptions-list-table.vue?vue&type=style&index=1&id=0346ff5a&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QScrollObserver,QInnerLoading,QSpinnerIos,QTable,QCard,QCardSection,QAvatar,QIcon,QImg,QItem,QItemSection,QSkeleton,QItemLabel,QCardActions,QBtn,QSpinner,QBtnDropdown} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QScrollObserver,QInnerLoading,QSpinnerIos,QTable,QCard,QCardSection,QAvatar,QIcon,QImg,QItem,QItemSection,QSkeleton,QItemLabel,QCardActions,QBtn,QSpinner,QBtnDropdown})

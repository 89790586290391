
// import {VueAvatar} from 'vue-avatar-editor-improved'
import { mapGetters, mapActions } from "vuex";
// import {i18n} from '@/i18n'
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/database"; // for realtime database

import { UserModel } from "@/modules/auth/user-model";
// import moment from 'moment';
const { fields } = UserModel;

export default {
  name: "app-prescription-form-modal",
  props: ["prescriptionDialog", "visible"],
  data() {
    return {
      description: "",
      title: "",
      image: "",
      // image:"",
      rotation: 0,
      scale: 1,
      borderRadius: 0,
      dialogImageUrl: "",
      // dialogVisible: false,
      uploadIcon: "img:/images/upload-prescription.svg",
      prescripitons: [],
      addedPrescInMonth: 0,
      currentMonth: "",
      currentYear: "",
      // canNotAdd:false,
      rawFile: null,
      fileName: "",
      path: "",
      downloadURL: "",
      file: "",
      fileExist: false,
      progress: null,
      fileSizeExceeded: false,
      btn_disabled: false,
      userPrescription: {},
      userPrescriptionLoading: false,
      recordId: null,
      payload: {},
      // progressBar:null,
    };
  },
  // watch:{
  //     user(newvalue,oldvalue){
  //         console.log('newvalue',newvalue)
  //         console.log('oldvalue',oldvalue)
  //         this.userPrescription = newvalue.prescription
  //         console.log('user prescription',this.userPrescription);
  //         let today = new Date()
  //         this.currentMonth = moment(today).format("MMM")
  //         this.currentYear = today.getFullYear()
  //         console.log('current Month',this.currentMonth);
  //         console.log('current Year',this.currentYear);
  //         for (const [key, value] of Object.entries(this.userPrescription)) {
  //             console.log(`${key}: ${value}`);
  //             console.log('keyyy',moment(key).format("MMM"));
  //             console.log('keyyy year',moment(key).format("YYYY"));

  //             let prescriptionMonth = moment(key.toString()).format("MMM")
  //             let prescriptionYear = moment(key).format("YYYY")
  //             if(this.currentMonth == prescriptionMonth && this.currentYear == prescriptionYear){
  //                 this.addedPrescInMonth = this.addedPrescInMonth + 1
  //             }
  //         }
  //         if(this.addedPrescInMonth > this.prescriptionMaxCount - 1){
  //             console.log('you can add three only')
  //             this.canNotAdd = true
  //         }
  //     }
  // },
  computed: {
    progressBar() {
      return this.progress / 100;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      saveLoading: "prescriptions/form/saveLoading",
      recordCreated: "prescriptions/form/recordCreated",
      user: "prescriptions/list/user",
    }),
    openDialog: {
      get() {
        return this.prescriptionDialog;
      },
      set() {
        this.$emit("close");
      },
    },
    dialogVisible: {
      get() {
        // setTimeout(() => {
        return this.visible;
        // },500)
      },
      set() {
        this.$emit("close");
      },
    },
    fields() {
      return fields;
    },
  },
  // components: {
  //     VueAvatar: VueAvatar
  // },
  methods: {
    ...mapActions({
      doCreate: "prescriptions/form/doCreate",
      doConvertImage: "prescriptions/form/doConvertImage",
      doFetchUser: "prescriptions/list/doFetchUser",
      doUpdate: "prescriptions/form/doUpdate",
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    close() {
      this.$emit("close");
    },
    saveClicked: function saveClicked() {
      var img = this.$refs.vueavatar.getImageScaled();
      this.$refs.image.src = img.toDataURL();
    },
    onImageReady: function onImageReady() {
      this.scale = 1;
      this.rotation = 0;
    },
    generateId() {
      return firebase.firestore().collection(`ids`).doc().id;
    },
    async updateUserPrescriptions() {
      let today = Date.now();
      let user = await firebase
        .firestore()
        .collection("user")
        .doc(this.currentUser.id)
        .get();
      let userPrescription = user.data().prescription;
      if (userPrescription) {
        userPrescription[today] = this.recordId;
        await firebase
          .firestore()
          .collection("user")
          .doc(this.currentUser.id)
          .update({
            prescription: userPrescription,
          });
      } else {
        let userPrescriptionNew = {};
        userPrescriptionNew[today] = this.recordId;
        await firebase
          .firestore()
          .collection("user")
          .doc(this.currentUser.id)
          .update({
            prescription: userPrescriptionNew,
          });
      }
    },
    async publish() {
      this.userPrescriptionLoading = true;
      this.recordId = this.generateId();
      this.payload = {
        id: this.recordId,
        title: this.title,
        description: this.description,
        viewers: {},
        subscribers: {},
        postStatus: "accepted",
        image: null,
        isActive: true,
      };
      this.payload.subscribers[this.currentUser.id] = true;

      if (!this.rawFile) {
        await this.doCreate(this.payload);
        // this.$emit('publish')
        console.log("if");
        this.userPrescriptionLoading = false;
        await this.updateUserPrescriptions();
        this.$emit("publish");
      } else {
        console.log("else");

        await this.uploadFromRequest();
      }

      // let today = Date.now()
      // let user = await firebase.firestore().collection('user').doc(this.currentUser.id).get()
      // console.log('user',user.data())
      // let userPrescription = user.data().prescription
      // if(userPrescription) {
      //     userPrescription[today] = this.recordId
      //     await firebase.firestore().collection('user').doc(this.currentUser.id).update(
      //     {
      //         prescription: userPrescription
      //     }
      //     )
      // }
      // else {
      //     let userPrescriptionNew = {}
      //     userPrescriptionNew[today] = this.recordId
      //     await firebase.firestore().collection('user').doc(this.currentUser.id).update(
      //     {
      //         prescription: userPrescriptionNew
      //     }
      //     )
      // }

      // this.updateUserPrescriptions()
      // this.$emit('publish')
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      // this.dialogVisible = true;
    },
    input(value) {
      // console.log('input',value[0]['publicUrl'])
      this.image = value[0]["publicUrl"];
    },
    openImageUpload() {
      const fileUpload = document.getElementById("file-upload");
      fileUpload.click();
    },
    async readUrl(event) {
      const profilePicture = document.getElementById("profile-pic");
      if (event.target.files && event.target.files[0]) {
        this.rawFile = event.target.files[0];
        this.fileName = event.target.files[0].name;
        var reader = new FileReader();
        reader.onload = function (e) {
          // console.log('target',e.target.result)
          profilePicture.src = e.target.result;
          this.file = e.target.result;
        };

        reader.readAsDataURL(event.target.files[0]);
        this.fileExist = true;
        var totalBytes = this.rawFile.size;
        var _size = Math.floor(totalBytes / 1000000);
        // }
        if (_size > 10) {
          this.fileExist = false;
          this.fileSizeExceeded = true;
        } else {
          //     await this.uploadFromRequest()
          this.fileExist = true;
          this.fileSizeExceeded = false;
        }
      }
    },

    async uploadFromRequest() {
      this.btn_disabled = true;
      //   console.log('schema : ',this.fields.avatarsIam.fileSchema)
      var storage = firebase.storage().ref();
      var today = Date.now();
      var uploadTask = storage.child(
        `prescription/${this.currentUser.id}/${this.recordId}/${today}`
      );
      // .put(this.rawFile);
      console.log(uploadTask);

      // const file = this.$refs.fileInput.files[0]

      // Create a reference to the file in Firebase Storage
      // const storageRef = storage.ref().child(file.name)

      // Upload the file to Firebase Storage
      uploadTask
        .put(this.rawFile)
        .then((snapshot) => {
          console.log("File uploaded:", snapshot.metadata.fullPath);

          // Get the download URL of the uploaded file
          uploadTask
            .getDownloadURL()
            .then(async (downloadURL) => {
              this.downloadURL = downloadURL;
              // var httpsReference = storage.refFromURL(this.downloadURL);
              // console.log('https reference',httpsReference);
              console.log(downloadURL);
              this.payload.image = {};
              this.payload.image[today] = this.downloadURL;

              await this.doCreate(this.payload);
              await this.updateUserPrescriptions();
              this.$emit("publish");
              this.userPrescriptionLoading = false;
              console.log("error final");
            })
            .catch((error) => {
              console.error("Error getting download URL:", error);
            });
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
        });
    },
  },
  mounted() {
    this.$q.iconSet.field.error = "img:/images/invalid-email.png";
    // console.log('lkkk',this.$refs.vueavatar)
    // this.$refs.vueavatar.loadImage("/images/upload-prescription.svg")
  },
  async created() {},
};
